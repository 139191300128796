.feature-highlighter {
	position: relative;

	&:before {
		background-color: #fa4b4b;
		border-radius: 50px;
		box-shadow: 0 1px 8px 0 #fa4b4b;
		content: '';
		display: block;
		height: 8px;
		position: absolute;
		width: 8px;
		z-index: 10;
	}
}
