.dp-filters-list {
	$t: &;

	color: var(--dp-list-color);

	$padding-left: 18px;

	&-item {
		color: var(--dp-list-color);
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
		line-height: 26px;
		padding: 0 9px 0 $padding-left;

		&:not(.static) {
			cursor: pointer;

			&:hover {
				background-color: var(--dp-list-item-hover-bg);
				color: var(--dp-list-item-hover-color);

				#{$t}-item-counter {
					color: var(--dp-list-item-hover-counter-color);
				}
			}
		}

		&-counter {
			color: var(--dp-list-item-counter-color);
			min-width: 14px;
			padding-left: 10px;
			text-align: right;
		}
	}

	ul {
		#{$t}-item {
			padding-left: $padding-left * 2;
		}
	}

	&-dotted {
		#{$t}-item-text {
			$indent-right: 6px;

			align-items: center;
			display: flex;

			&:before {
				background-color: var(--dp-list-item-dot);
				border-radius: 50%;
				content: '';
				height: 4px;

				// dp-dot-18
				margin: 7px;
				margin-right: 7px + $indent-right;
				position: relative;
				width: 4px;
			}

			&.highlighted {
				&:before {
					background-color: var(--dp-list-item-dot-highlighted);
					height: 8px;
					margin: 5px;
					margin-right: 5px + $indent-right;
					width: 8px;
				}
			}
		}
	}

	&-collapsed {
		#{$t}-item-text {
			align-items: center;
			display: flex;

			svg {
				fill: var(--dp-list-item-chevron);
				margin-right: 3px;
				padding: 3px 3px 3px 0;

				&:hover {
					fill: var(--dp-list-item-chevron-hover);
				}
			}
		}
	}
}

.dp-list {
	$t: &;

	color: var(--dp-list-color);

	$padding-left: 18px;

	@for $level from 1 to 5 {
		&.level-#{$level} {
			margin-left: 0;

			#{$t}-item-content {
				padding-left: $padding-left * ($level + 1);
			}
		}
	}

	&-item {
		&.dot {
			$indent-right: 6px;

			#{$t}-item-content {
				&:before {
					background-color: var(--dp-list-item-dot);
					border-radius: 50%;
					content: '';
					height: 4px;
					margin: 7px;
					margin-right: 7px + $indent-right;
					position: relative;
					width: 4px;
				}
			}

			&.highlighted {
				&:before {
					background-color: var(--dp-list-item-dot-highlighted);
					height: 8px;
					margin: 5px;
					margin-right: 5px + $indent-right;
					width: 8px;
				}
			}
		}

		&-content {
			align-items: center;
			display: flex;
			flex-wrap: nowrap;
			justify-content: space-between;
			line-height: 26px;
			padding: 0 9px 0 $padding-left;

			&:hover {
				background-color: var(--dp-list-item-hover-bg);
				color: var(--dp-list-item-hover-color);
			}
		}

		&-collapser {
			margin-right: 3px;
			padding: 3px 3px 3px 0;

			&.collapsed {
				i, // for search filters list
				svg {
					transform: rotate(-90deg);
				}
			}

			svg {
				fill: var(--dp-list-item-chevron);

				&:hover {
					fill: var(--dp-list-item-chevron-hover);
				}
			}

			i { // for search filters list
				color: var(--dp-list-item-chevron);
				display: block;

				&:hover {
					color: var(--dp-list-item-chevron-hover);
				}
			}
		}

		&-count {
			color: var(--dp-list-item-counter-color);
			// min-width: 14px;
			// padding-left: 10px;
			padding-left: 3px;
			text-align: right;
		}

		&-text {
			color: var(--dp-list-color);
			display: flex;
			flex: 1;
			flex-wrap: nowrap;

			.text {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}
	}
}
