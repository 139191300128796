@font-face {
	font-family: 'Sans Regular';
	font-stretch: normal;
	font-style: normal;
	src: url('https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff2/ttf/SourceSansPro-Regular.ttf.woff2') format('woff2');
}

@font-face {
	font-family: 'Sans Semibold';
	font-stretch: normal;
	font-style: normal;
	src: url('https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff2/ttf/SourceSansPro-Semibold.ttf.woff2') format('woff2');
}

@font-face {
	font-family: 'Sans Italic';
	font-stretch: normal;
	font-style: normal;
	src: url('https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff2/ttf/SourceSansPro-It.ttf.woff2') format('woff2');
}

$font-sans: 'Sans Regular', arial, sans-serif;
$font-sans-semibold: 'Sans Semibold', arial, sans-serif;
$font-sans-italic: 'Sans Italic', arial, sans-serif;

$text-size-10x12: (size: 10px, line-height: 12px);
$text-size-12x16: (size: 12px, line-height: 16px);
$text-size-12x18: (size: 12px, line-height: 18px);
$text-size-14x18: (size: 14px, line-height: 18px);
$text-size-14x24: (size: 14px, line-height: 24px);
$text-size-16x18: (size: 16px, line-height: 18px);
$text-size-16x24: (size: 16px, line-height: 24px);
$text-size-18x24: (size: 18px, line-height: 24px);
$text-size-18x30: (size: 18px, line-height: 30px);
$text-size-24x30: (size: 24px, line-height: 30px);
$text-size-30x36: (size: 30px, line-height: 36px);

@function text-size($preset) {
	@return map-get($preset, 'size');
}

@function text-line-height($preset) {
	@return map-get($preset, 'line-height');
}

@mixin text($family: $font-sans, $preset: $text-size-10x12) {
	font-family: $family;
	font-size: text-size($preset);
	line-height: text-line-height($preset);
}
