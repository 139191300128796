@import './button.mixin';

button {
	cursor: pointer;
}

.dp-button {
	@include button;

	background: linear-gradient(225deg, var(--accent-color) 0%, var(--accent-color-secondary) 100%);
	border: none;
	color: var(--headers-font-color);

	svg {
		fill: var(--headers-font-color);
	}

	&:hover {
		background: var(--accent-color-secondary);
	}

	&:active {
		background: #4a3886;
	}

	&.left-icon {
		padding-left: 9px;
	}

	// Button types

	&.warning {
		background: transparent;
		border: 1px solid var(--warning-status-color);
		color: var(--warning-status-color);

		svg {
			fill: var(--warning-status-color);
		}

		&:hover {
			background: #f67e00;
			border-color: #f67e00;
		}

		&:active {
			background: #e64c00;
			border-color: #e64c00;
		}

		&:hover,
		&:active {
			color: var(--headers-font-color);

			svg {
				fill: var(--headers-font-color);
			}
		}
	}
}

.dp-button .dp-button.primary {
	background: #428024;
	padding: 0 18px;

	&:hover {
		background-color: #6fad2f;
	}

	&:active {
		background-color: #9bc837;
	}
}

.dp-button.secondary {
	background: transparent;
	border: 1px solid var(--secondary-font-color);
	color: var(--secondary-font-color);
	padding: 0 18px;

	&:hover {
		background: var(--main-icons-color);
		border-color: var(--main-icons-color);
		color: var(--headers-font-color);
	}

	&:active {
		background: var(--divider-bg-color);
		border-color: var(--divider-bg-color);
		color: var(--headers-font-color);
	}
}

.dp-button[disabled],
.dp-button[disabled]:hover {
	background: var(--divider-bg-color);
	color: var(--button-disabled-color);
	cursor: default;
}

.dp-button.action {
	background: #9bc837;
	padding: 0 18px;

	&:hover {
		background-color: #6fad2f;
	}

	&:active {
		background-color: #428024;
	}
}

.dp-button-close {
	background: none;
	border: none;
	cursor: pointer;
	padding: 11px;

	svg {
		fill: var(--main-icons-color);
	}
}

.dp-button-outline {
	@include button;

	background: none;
	border: 1px solid var(--secondary-active-color);
	color: var(--main-active-color);

	svg {
		fill: var(--main-active-color);
	}

	&:hover {
		background-color: $todo-button-outline-hover;
		border-color: $todo-button-outline-hover;
	}

	&:active {
		background-color: #08c;
		border-color: #08c;
	}

	&:hover,
	&:active {
		color: #fff;

		svg {
			fill: #fff;
		}
	}
}

.dp-button-text {
	@include button;

	background: none;
	border: 1px solid transparent;
	color: var(--dp-button-text-color);

	svg {
		fill: var(--dp-button-text-color);
	}

	&:hover {
		background-color: var(--hover-text-action);
		border-color: var(--hover-text-action);
	}

	&:active {
		background-color: var(--dp-button-text-active-bg);
		border-color: var(--dp-button-text-active-bg);
	}

	&[disabled],
	&.disabled {
		border-color: var(--dp-button-text-disabled-border);
		color: var(--dp-button-text-disabled-text);

		svg {
			fill: var(--dp-button-text-disabled-text);
		}
	}
}

.dp-button-null {
	background: none;
	border: none;
	cursor: pointer;
	margin: 0;
	padding: 0;
}
