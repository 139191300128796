@import 'global/fonts';

.dp-chips {
	$this: &;

	display: flex;
	flex-wrap: wrap;
	font: 12px 'Sans Regular';

	> * {
		align-items: center;
		background-color: var(--core-surfaces-surface-main);
		border: 1px solid var(--core-surfaces-divider-light);
		border-radius: var(--core-border-radius);
		box-sizing: border-box;
		color: var(--core-text-text-secondary);
		cursor: pointer;
		display: flex;
		height: 24px;
		margin: 0 6px 6px 0;
		overflow: hidden;
		padding: 3px;

		&:not(.disabled):hover {
			background-color: var(--core-neutral-neutral-20);
		}

		&.disabled {
			background-color: var(--gray-700);
			color: var(--gray-500);
			cursor: no-drop;

			#{$this}-left-icon {
				fill: var(--gray-500);
			}
		}

		&.left-icon {
			padding-right: 3px;
		}

		&.right-icon {
			padding-left: 3px;
		}

		&.both-icons {
			cursor: default;
			padding: 0 3px;
		}

		> svg {
			flex-shrink: 0;
		}
	}

	&-label {
		font-size: 12px;
		line-height: 14px;
		max-width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	&-left-icon {
		fill: var(--core-text-text-secondary);
		padding-right: 3px;
	}

	&-right-icon {
		cursor: pointer;
		fill: var(--core-text-text-secondary);
		padding-left: 3px;
	}
}

.dp-chips.small {
	@include text($preset: $text-size-12x18);

	> * {
		height: 18px;

		.dp-chips-label {
			align-items: center;
			display: flex;
			font-size: 10px;
			line-height: 12px;
		}
	}
}

.dark-theme {
	.dp-chips,
	&.dp-chips {
		> * {
			&:not(.disabled):hover {
				background-color: var(--core-neutral-neutral-50);
			}
		}
	}
}

.light-theme {
	.dp-chips,
	&.dp-chips {
		> * {
			&:not(.disabled):hover {
				background-color: var(--core-neutral-neutral-20);
			}
		}
	}
}
