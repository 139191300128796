@import 'make-color';

.light-theme,
:root {
	--main-active-color: #009ecc;
	--hover-active-color: #008abd;
	// TODO: Update colors, this is copy-paste from dark theme
	--secondary-bg-color: #fafafc;
	--secondary-active-color: #08c;
	--divider-bg-color: #666;
	--headers-font-color: #fff;
	--main-font-color: #474a59;
	--secondary-font-color: #474a59;
	--accent-color: #9d8fe9;
	--accent-color-secondary: #6350a2;
	--input-border: #ced0db;
	--input-border-hover: #6c6f80;
	--input-border-focus: #009ecc;
	--input-shadow-focus: rgba(48, 182, 221, 40%);

	// Size variables
	--small-border-radius: 3px;
	--big-border-radius: 6px;

	// Questionable
	--opacity: 1;

	// Gradients
	--preview-gradient: var(--pp-skeleton-gradient);

	// Digital platform
	--dp-icon-color: #6c6f80;
	--dp-icon-color-hover: #474a59;
	--dp-icon-color-disabled: #ced0db;

	// dp-list
	--dp-list-color: #1d1e26;
	--dp-list-item-hover-bg: #ebedf5;
	--dp-list-item-hover-color: var(--dp-list-color);
	--dp-list-item-counter-color: #acafbf;
	--dp-list-item-hover-counter-color: var(--dp-list-item-counter-color);
	--dp-list-item-dot: #acafbf;
	--dp-list-item-dot-highlighted: var(--dp-list-item-dot);
	--dp-list-item-chevron: var(--dp-icon-color);
	--dp-list-item-chevron-hover: #0a0a0e;

	// Search
	--search-result-content: #1d1e26;
	--search-result-header-visited: #8b5a9f;

	// Statuses
	--success-status-color: #67a300;
	--info-status-color: #009ecc;
	--warning-status-color: #fcaa00;
	--error-status-color: #fa4b4b;

	// Popup
	--popup-text-header-color: #525462;

	// Divider
	--divider-color: #e1e3eb;

	//Card
	--card-text-color: var(--gray-800);

	//Shadow variables
	--common-box-shadow: 0 1px 2px 0 #1d1e261a, 0 2px 10px 0 #1d1e261a;

	// blue
	--blue-darkest: #0079ad;
	--blue-dark: #008abd;
	--blue: #009ecc;
	--blue-rgb: #{make_rgb_color(#009ecc)};
	--blue-light: #c4eaf5;
	--blue-lightest: #e1f4fa;
	// green
	--green-darkest: #428024;
	--green-dark: #558a00;
	--green: #67a300;
	// amber
	--amber-darkest: #e06900;
	--amber-dark: #f68700;
	--amber: #fcaa00;
	--amber-light: #ffdd96;
	--amber-lightest: #ffedc9;
	--amber-50: #fff7e6;
	// red
	--red-darkest: #b32424;
	--red-dark: #cc2929;
	--red: #fa4b4b;
	--red-light: #fcc8c8;
	--red-lightest: #fde1e1;
	--pp-semantic-primary-50: #9D8FE9;
	--pp-semantic-primary-50-rgb: #{make_rgb_color(#9D8FE9)};
	--pp-surface-highest-rgb: #{make_rgb_color(#EBEDF5)};

	// skeleton
	// we use our custom skeletons color instead of UUI's
	--pp-skeleton-gradient: linear-gradient(90deg, var(--core-neutral-neutral-40) 0%, var(--core-neutral-neutral-10) 50%, var(--core-neutral-neutral-40) 100%);
	--pp-skeleton: var(--core-neutral-neutral-40);
}
