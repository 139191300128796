@import 'global/variables';
@import 'global/fonts';

* {
	scrollbar-width: thin;
}

body {
	@include text($font-sans, $text-size-14x18);

	background-color: #212359;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	margin: 0;
}

body > iframe {
	border: none;
}

a {
	@include link;

	text-decoration: none;

	&:focus {
		outline: none;
	}
}

ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

mark,
em {
	background: #ffe699;
	color: var(--gray-800);
	font-style: initial;
}

.dark-theme {
	mark,
	em {
		background: var(--amber);
		color: var(--amber-lightest);
	}
}

strong,
th {
	font-family: $font-sans-semibold;
	font-weight: normal;
}

button:focus,
input:focus {
	outline: none;
}

::-webkit-scrollbar {
	height: 10px;
	width: 10px;
}

::-webkit-scrollbar-track {
	background-color: #eee;
}

::-webkit-scrollbar-thumb {
	background-color: #c1c1c1;
	border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
	background-color: #a8a8a8;
}

h1, h2, h3, h4, h5, h6 {
	font-weight: 400;
}
