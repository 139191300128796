.page-header {
	grid-area: header;
	position: relative;
	z-index: 7;
}

.page-subheader {
	box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 10%);
	display: block;
	grid-area: subheader;
	position: sticky;
	top: -1px;
	z-index: 6;
}
