@import 'global/variables';

@mixin button {
	align-items: center;
	border-radius: var(--small-border-radius);
	box-sizing: border-box;
	cursor: pointer;
	display: flex;
	height: 36px;
	justify-content: center;
	padding: 0 12px;

	@include text($font-sans-semibold, $text-size-14x18);

	pp-icon {
		margin-right: 8px;
	}

	&.dp-big {
		height: 48px;

		@include text($font-sans-semibold, $text-size-16x24);
	}
}

$todo-button-outline-hover: #499cd7;
$todo-button-outline-hover-svg: #fff;
