@import 'make-color';

:root {
	// gray
	--gray-900: #1d1e26;
	--gray-900-rgb: #{make_rgb_color(#1d1e26)};
	--gray-850: #272833;
	--gray-800: #303240;
	--gray-750: #383b4a;
	--gray-700: #474a59;
	--gray-650: #585b69;
	--gray-625: #525462;
	--gray-600: #6c6f80;
	--gray-500: #acafbf;
	--gray-400: #ced0db;
	--gray-350: #dadce6;
	--gray-300: #e1e3eb;
	--gray-200: #ebedf5;
	--gray-100: #f5f6fa;
	--gray-50: #fafafc;
	--purple-100: #a171b0;
	--purple-50: #b67fc7;
}
