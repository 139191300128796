.dp-dot {
	background-color: var(--divider-bg-color);
	border-radius: 50%;
	content: '';
	height: 4px;
	width: 4px;

	&-12 {
		margin: 4px;
	}

	&-18 {
		margin: 7px;
	}

	&-24 {
		margin: 10px;
	}

	&-30 {
		margin: 13px;
	}

	&-36 {
		margin: 16px;
	}
}

.dp-dot-chain {
	display: flex;

	&-link {
		padding-left: 12px;
		position: relative;

		&:first-child {
			padding-left: 0;

			&:before {
				display: none;
			}
		}

		&:before {
			background-color: var(--secondary-font-color);
			border-radius: 50%;
			content: '';
			display: block;
			height: 3px;
			left: 5px;
			position: absolute;
			top: calc(50% - 1px);
			width: 3px;
		}

		&-light {
			&:before {
				background-color: var(--dp-list-item-dot);
			}
		}
	}
}

.dp-dot-chain-after {
	display: flex;

	&-link {
		padding-right: 12px;
		position: relative;

		&:after {
			background-color: var(--dp-list-item-dot);
			border-radius: 50%;
			content: '';
			display: block;
			height: 3px;
			position: absolute;
			right: 5px;
			top: calc(50% - 1px);
			width: 3px;
		}

		&-dark {
			&:after {
				background-color: var(--secondary-font-color);
			}
		}
	}
}
