.uui-light-theme {
	--core-border-radius: 3px;
	--core-controls-control-bg-disabled: #FAFAFC;
	--core-controls-control-bg-hover: #6C6F80;
	--core-controls-control-bg: #FFF;
	--core-controls-control-border-disabled: #E1E3EB;
	--core-controls-control-border-focus: #009ECC;
	--core-controls-control-border-hover: #6C6F80;
	--core-controls-control-border: #CED0DB;
	--core-controls-control-icon-disabled: #ACAFBF;
	--core-controls-control-icon: #ACAFBF;
	--core-controls-control-placeholder-disabled: #ACAFBF;
	--core-controls-control-placeholder: #ACAFBF;
	--core-controls-control-text-disabled: #6C6F80;
	--core-controls-control-text: #303240;
	--core-icons-icon-active: #303240;
	--core-icons-icon-disabled: #ACAFBF;
	--core-icons-icon-hover: #474A59;
	--core-icons-icon: #6C6F80;
	--core-internal-accent-contrast: #FFF;
	--core-internal-critical-contrast: #FFF;
	--core-internal-error-contrast: #FFF;
	--core-internal-info-contrast: #FFF;
	--core-internal-primary-contrast: #FFF;
	--core-internal-secondary-contrast: #FFF;
	--core-internal-success-contrast: #FFF;
	--core-internal-undefined-color: rgba( #FFF,0%);
	--core-internal-warning-contrast: #303240;
	--core-links-link-hover: #0086AD;
	--core-links-link-visited-hover: #5E0B6F;
	--core-links-link-visited: #860F9E;
	--core-links-link: #009ECC;
	--core-neutral-neutral-0: #FFF;
	--core-neutral-neutral-10: #F5F6FA;
	--core-neutral-neutral-100: #000;
	--core-neutral-neutral-20: #EBEDF5;
	--core-neutral-neutral-30: #E1E3EB;
	--core-neutral-neutral-40: #CED0DB;
	--core-neutral-neutral-5: #FAFAFC;
	--core-neutral-neutral-50: #ACAFBF;
	--core-neutral-neutral-60: #6C6F80;
	--core-neutral-neutral-70: #474A59;
	--core-neutral-neutral-80: #303240;
	--core-neutral-neutral-90: #1D1E26;
	--core-other-outline-focus: rgba(#009ECC,50%);
	--core-other-skeleton-gradient: linear-gradient(90deg, #CED0DB 0%, #F5F6FA 50%, #CED0DB 100%);
	--core-other-skeleton: #CED0DB;
	--core-semantic-accent-10: #EBF3D8;
	--core-semantic-accent-20: #C1E288;
	--core-semantic-accent-5: #FCFFF5;
	--core-semantic-accent-50: #67A300;
	--core-semantic-accent-60: #528500;
	--core-semantic-accent-70: #396F1F;
	--core-semantic-critical-10: #FDE1E1;
	--core-semantic-critical-20: #FBB6B6;
	--core-semantic-critical-5: #FEF6F6;
	--core-semantic-critical-50: #FF4242;
	--core-semantic-critical-60: #E22A2A;
	--core-semantic-critical-70: #AD0000;
	--core-semantic-error-10: #FDE1E1;
	--core-semantic-error-20: #FBB6B6;
	--core-semantic-error-5: #FEF6F6;
	--core-semantic-error-50: #FF4242;
	--core-semantic-error-60: #E22A2A;
	--core-semantic-error-70: #AD0000;
	--core-semantic-info-10: #E1F4FA;
	--core-semantic-info-20: #A0DDEE;
	--core-semantic-info-5: #F5FDFF;
	--core-semantic-info-50: #009ECC;
	--core-semantic-info-60: #0086AD;
	--core-semantic-info-70: #006B8A;
	--core-semantic-primary-10: #E1F4FA;
	--core-semantic-primary-20: #A0DDEE;
	--core-semantic-primary-5: #F5FDFF;
	--core-semantic-primary-50: #009ECC;
	--core-semantic-primary-60: #0086AD;
	--core-semantic-primary-70: #006B8A;
	--core-semantic-secondary-10: #EBEDF5;
	--core-semantic-secondary-20: #E1E3EB;
	--core-semantic-secondary-5: #F5F6FA;
	--core-semantic-secondary-50: #6C6F80;
	--core-semantic-secondary-60: #474A59;
	--core-semantic-secondary-70: #303240;
	--core-semantic-success-10: #EBF3D8;
	--core-semantic-success-20: #C1E288;
	--core-semantic-success-5: #FCFFF5;
	--core-semantic-success-50: #67A300;
	--core-semantic-success-60: #528500;
	--core-semantic-success-70: #396F1F;
	--core-semantic-warning-10: #FFEDC9;
	--core-semantic-warning-20: #FFD785;
	--core-semantic-warning-5: #FFFCF5;
	--core-semantic-warning-50: #FCAA00;
	--core-semantic-warning-60: #FF9000;
	--core-semantic-warning-70: #BD5800;
	--core-shadow-level-1: 0 0 3px 0 rgba(#1D1E26, 0.05), 0 3px 6px 0 rgba(#1D1E26, 0.10);
	--core-shadow-level-2: 0 6px 12px 0 rgba(#1D1E26, 0.05), 0 3px 12px 0 rgba(#1D1E26, 0.10), ;
	--core-shadow-level-3: 0 0 10px 0 rgba(#1D1E26, 0.05), 0 6px 36px 0 rgba(#1D1E26, 0.12), ;
	--core-surfaces-app-bg: #F5F6FA;
	--core-surfaces-divider-light: #E1E3EB;
	--core-surfaces-divider: #CED0DB;
	--core-surfaces-overlay: rgba(29, 30, 38, 80%);
	--core-surfaces-surface-higher: #F5F6FA;
	--core-surfaces-surface-highest: #EBEDF5;
	--core-surfaces-surface-main: #FFF;
	--core-surfaces-surface-main-widget:#4351cd;
	--core-surfaces-surface-sunken: #EBEDF5;
	--core-text-text-bg-highlight: #FFEDC9;
	--core-text-text-critical: #AD0000;
	--core-text-text-disabled: #ACAFBF;
	--core-text-text-info: #006B8A;
	--core-text-text-primary: #303240;
	--core-text-text-secondary: #474A59;
	--core-text-text-success: #396F1F;
	--core-text-text-tertiary: #6C6F80;
	--core-text-text-warning: #BD5800;
}
