$primary_blue: #009ecc;
$primary_green: #a3c644;
$primary_gray: #464547;
$primary_white: #fff;

$secondary_blue_dark: #008abd;
$secondary_green_dark: #7f993a;
$secondary_gray_medium: #666;
$secondary_gray: #999;
$secondary_gray_light: #ced0db;

$additional_yellow: #ffde56;
$additional_orange: #ff9b19;
$additional_red: #cb2323;
$additional_black: #222;
$additional_gray_bg: #f5f5f5;
$additional_gray_bg_dark: #ebeef0;

$selected-filter-include-border: #e1e3eb;
$selected-filter-include-color: #303240;

$dp-card-padding-left: 12px;
$dp-card-padding-right: 12px;

$grid-column-gap: 18px;
$grid-row-gap: 18px;

$mobile-grid-column-gap: 12px;
$mobile-grid-row-gap: 12px;

$breakpoint-tablet: 1000px; // also it is max screen of PowerPoint Add-in
$breakpoint-phone: 767px;
$breakpoint-small-desktop-min: 1280px;
$breakpoint-small-desktop-max: 1540px;
$breakpoint-small-desktop-height: 864px;
$breakpoint-small-office-height: 700px;
$breakpoint-initial-office-width: 335px; // initial width minus scroll width
$breakpoint-small-office-width: 300px;

// Search page

@mixin link {
	color: var(--main-active-color);
	cursor: pointer;

	&:hover {
		color: var(--hover-active-color);
	}
}

@mixin loading {
	background: var(--pp-skeleton-gradient);
	border-radius: 6px;
}
