@import 'make-color';

.dark-theme {
	// HEX variables
	--main-bg-color: #1d1e26;
	--secondary-bg-color: #272933;
	--main-hover-color: #17181f;
	--header-bg-color: #303240;
	--divider-bg-color: #474a59;
	--headers-font-color: #fff;
	--main-font-color: #f5f6fa;
	--secondary-font-color: #acafbf;
	--main-icons-color: #8a8c99;
	--main-active-color: #4bc9ff;
	--hover-active-color: #30b6dd;
	--hover-secondary-color: #95eaff;
	--secondary-active-color: #08c;
	--accent-color: #9d8fe9;
	--accent-color-secondary: #6350a2;
	--success-status-color: #9bc837;
	--warning-status-color: #fcaa00;
	--error-status-color: #fa4b4b;
	--button-disabled-color: #8a8c99;
	--input-border: #6c6f80;
	--input-border-hover: #ced0db;
	--input-border-focus: #009ecc;
	--input-shadow-focus: rgba(48, 182, 221, 40%);
	// new
	--hover-text-action: #1d1e26;
	--dp-button-text-color: #4bc9ff;
	--dp-button-text-active-bg: #5a5d6d;
	--dp-button-text-disabled-border: #5a5d6d;
	--dp-button-text-disabled-text: #8a8c99;
	--icon-color: #e1e3eb;
	--icon-color-hover: #fff;
	--dp-search-bar-icon: #6c6f80;
	--dropdown-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 20%), 0 3px 10px 0 rgba(0, 0, 0, 40%);

	// RGB variables
	--main-hover-color-rgb: 23, 24, 31;

	// Size variables
	--small-border-radius: 3px;
	--big-border-radius: 6px;

	// Questionable
	--opacity: 0.2;

	// Gradients
	--preview-gradient: var(--pp-skeleton-gradient);

	// Digital platform
	--dp-icon-color: #e1e3eb;

	// dp-list
	--dp-list-color: #ced0db;
	--dp-list-item-hover-bg: #3f4351;
	--dp-list-item-hover-color: var(--main-font-color);
	--dp-list-item-counter-color: var(--dp-list-color);
	--dp-list-item-hover-counter-color: var(--dp-list-item-hover-color);
	--dp-list-item-dot: var(--divider-bg-color);
	--dp-list-item-dot-highlighted: var(--main-active-color);
	--dp-list-item-chevron: var(--secondary-font-color);
	--dp-list-item-chevron-hover: var(--main-font-color);

	//Card
	--card-text-color: #fff;

	//Shadow variables
	--common-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 20%), 0 3px 10px 0 rgba(0, 0, 0, 40%);

	// blue
	--blue-darkest: #008ace;
	--blue-dark: #4ba5d1;
	--blue: #75b3d1;
	--blue-rgb: #{make_rgb_color(#75b3d1)};
	--blue-light: #364e63;
	--blue-lightest: #334052;
	// green
	--green-darkest: #690;
	--green-dark: #89c219;
	--green: #a6d151;
	// amber
	--amber-darkest: #d67b0b;
	--amber-dark: #e3a82d;
	--amber: #fcc042;
	--amber-light: #53413c;
	--amber-lightest: #423d3e;
	// red
	--red-darkest: #cc482d;
	--red-dark: #e6674e;
	--red: #f78c77;
	--red-light: #694548;
	--red-lightest: #473943;
	--pp-semantic-primary-50: #6350A2;
	--pp-semantic-primary-50-rgb: #{make_rgb_color(#6350A2)};
	--pp-surface-highest-rgb: #{make_rgb_color(#383B4A)};

	// skeleton
	// we use our custom skeletons color instead of UUI's
	--pp-skeleton-gradient: linear-gradient(90deg, var(--core-neutral-neutral-50) 0%, var(--core-neutral-neutral-80) 50%, var(--core-neutral-neutral-50)  100%);
	--pp-skeleton: var(--core-neutral-neutral-50);


}
