.uui-dark-theme {
	--core-border-radius: 3px;
	--core-controls-control-bg-disabled: #383B4A;
	--core-controls-control-bg-hover: #383B4A;
	--core-controls-control-bg: #272833;
	--core-controls-control-border-disabled: #585B69;
	--core-controls-control-border-focus: #48A4D0;
	--core-controls-control-border-hover: #ACAFBF;
	--core-controls-control-border: #585B69;
	--core-controls-control-icon-disabled: #6C6F80;
	--core-controls-control-icon: #6C6F80;
	--core-controls-control-placeholder-disabled: #6C6F80;
	--core-controls-control-placeholder: #ACAFBF;
	--core-controls-control-text-disabled: #ACAFBF;
	--core-controls-control-text: #FAFAFC;
	--core-icons-icon-active: #FAFAFC;
	--core-icons-icon-disabled: #6C6F80;
	--core-icons-icon-hover: #FAFAFC;
	--core-icons-icon: #E1E3EB;
	--core-internal-accent-contrast: #FFF;
	--core-internal-critical-contrast: #FFF;
	--core-internal-error-contrast: #FFF;
	--core-internal-info-contrast: #FFF;
	--core-internal-primary-contrast: #FFF;
	--core-internal-secondary-contrast: #FFF;
	--core-internal-success-contrast: #FFF;
	--core-internal-undefined-color: rgba( #FFF,0%);
	--core-internal-warning-contrast: #303240;
	--core-links-link-hover: #7DBCDB;
	--core-links-link-visited-hover: #AE8BF4;
	--core-links-link-visited: #A55CEF;
	--core-links-link: #48A4D0;
	--core-neutral-neutral-0: #FFF;
	--core-neutral-neutral-10: #E1E3EB;
	--core-neutral-neutral-100: #000;
	--core-neutral-neutral-20: #ACAFBF;
	--core-neutral-neutral-30: #6C6F80;
	--core-neutral-neutral-40: #585B69;
	--core-neutral-neutral-5: #FAFAFC;
	--core-neutral-neutral-50: #474A59;
	--core-neutral-neutral-60: #383B4A;
	--core-neutral-neutral-70: #303240;
	--core-neutral-neutral-80: #272833;
	--core-neutral-neutral-90: #1D1E26;
	--core-other-outline-focus: rgba(#008ACE,50%);
	--core-other-skeleton-gradient: linear-gradient(90deg, #474A59 0%, #272833 50%, #474A59  100%);
	--core-other-skeleton: #6C6F80;
	--core-semantic-accent-10: #39443D;
	--core-semantic-accent-20: #505F49;
	--core-semantic-accent-5: #343A3B;
	--core-semantic-accent-50: #690;
	--core-semantic-accent-60: #83B918;
	--core-semantic-accent-70: #A6D151;
	--core-semantic-critical-10: #473943;
	--core-semantic-critical-20: #6B4447;
	--core-semantic-critical-5: #3C343E;
	--core-semantic-critical-50: #CC482D;
	--core-semantic-critical-60: #E56248;
	--core-semantic-critical-70: #F78C77;
	--core-semantic-error-10: #473943;
	--core-semantic-error-20: #6B4447;
	--core-semantic-error-5: #3C343E;
	--core-semantic-error-50: #CC482D;
	--core-semantic-error-60: #E56248;
	--core-semantic-error-70: #F78C77;
	--core-semantic-info-10: #334052;
	--core-semantic-info-20: #3E586F;
	--core-semantic-info-5: #2C394B;
	--core-semantic-info-50: #008ACE;
	--core-semantic-info-60: #48A4D0;
	--core-semantic-info-70: #7DBCDB;
	--core-semantic-primary-10: #334052;
	--core-semantic-primary-20: #3E586F;
	--core-semantic-primary-5: #2C394B;
	--core-semantic-primary-50: #008ACE;
	--core-semantic-primary-60: #48A4D0;
	--core-semantic-primary-70: #7DBCDB;
	--core-semantic-secondary-10: #303240;
	--core-semantic-secondary-20: #383B4A;
	--core-semantic-secondary-5: #272833;
	--core-semantic-secondary-50: #474A59;
	--core-semantic-secondary-60: #585B69;
	--core-semantic-secondary-70: #6C6F80;
	--core-semantic-success-10: #39443D;
	--core-semantic-success-20: #505F49;
	--core-semantic-success-5: #343A3B;
	--core-semantic-success-50: #690;
	--core-semantic-success-60: #83B918;
	--core-semantic-success-70: #A6D151;
	--core-semantic-warning-10: #47433D;
	--core-semantic-warning-20: #625747;
	--core-semantic-warning-5: #3F3B3B;
	--core-semantic-warning-50: #F1A105;
	--core-semantic-warning-60: #F4B83A;
	--core-semantic-warning-70: #FFD06D;
	--core-shadow-level-1: 0 0 4px 0 rgba(0,0,0,20%), 0 3px 6px 0 rgba(0,0,0,20%);
	--core-shadow-level-2: 0 1px 2px 0 rgba(0,0,0,20%), 0 4px 22px 3px rgba(0,0,0,26%);
	--core-shadow-level-3: 0 1px 2px 0 rgba(0,0,0,20%), 0 3px 25px 3px rgba(0,0,0,56%);
	--core-surfaces-app-bg: #090a23;
	--core-surfaces-divider-light: #383B4A;
	--core-surfaces-divider: #474A59;
	--core-surfaces-overlay: rgba(29, 30, 38, 80%);
	--core-surfaces-surface-higher: #54565a;
	--core-surfaces-surface-highest: #272a73;//
	--core-surfaces-surface-main: #202359;
	--core-surfaces-surface-main-widget: #232538;
	--core-surfaces-surface-sunken: #383B4A;
	--core-text-text-bg-highlight: #625747;
	--core-text-text-critical: #F78C77;
	--core-text-text-disabled: #6C6F80;
	--core-text-text-info: #7DBCDB;
	--core-text-text-primary: #FAFAFC;
	--core-text-text-secondary: #E1E3EB;
	--core-text-text-success: #A6D151;
	--core-text-text-tertiary: #ACAFBF;
	--core-text-text-warning: #FFD06D;
}
