$todo-search-bar-tips-icon-active: #0e74e2;

.dp-search-bar {
	max-width: 100%;
	position: relative;

	input[type='text'] {
		@include text($font-sans, $text-size-16x24);

		background-color: var(--core-surfaces-app-bg);
		border-radius: var(--small-border-radius);
		box-sizing: border-box;
		color: var(--core-text-text-primary);
		height: 36px;
		padding: 1px 40px;
		width: 100%;

		&::placeholder {
			color: var(--core-text-text-tertiary);
		}
	}

	&-tips-icon {
		cursor: pointer;
		left: 2px;
		// TODO: research how to set styles order on build
		// ! Now common styles builds after component styles
		padding: 8px !important;
		position: absolute;
		top: 50%;
		transform: translate(0, -50%);

		pp-icon {
			fill: var(--core-icons-icon);
		}

		&:hover pp-icon {
			fill: var(--secondary-active-color);
		}

		&:active svg {
			fill: $todo-search-bar-tips-icon-active;
		}
	}

	&-search-icon {
		left: 12px;
		position: absolute;
		top: 50%;
		transform: translate(0, -50%);

		svg {
			fill: var(--core-text-text-tertiary);
		}
	}

	&-clear {
		cursor: pointer;
		padding: 9px;
		position: absolute;
		right: 0;
		top: 0;

		svg {
			fill: var(--core-text-text-tertiary);
		}

		&:hover {
			svg {
				fill: var(--core-text-text-primary);
			}
		}
	}
}
