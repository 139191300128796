.input-search {
	border: 1px solid var(--core-controls-control-border-disabled);

	&:hover {
		border: 1px solid var(--core-controls-control-border-hover);
	}

	&:focus {
		border: 1px solid var(--core-controls-control-border-focus);
	}
}
