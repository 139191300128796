.dp-autosuggest {
	--gap: 0;

	background-color: var(--gray-800);
	border: 1px solid var(--gray-600);
	border-radius: var(--small-border-radius);
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 35%), 0 3px 10px 0 rgba(0, 0, 0, 39%);
	box-sizing: border-box;
	color: var(--gray-500);
	font: 16px 'Sans Regular';
	overflow: hidden;
	padding: 9px 0;
	position: absolute;
	top: calc(100% + 5px);
	width: 100%;
	z-index: 5;

	&-item {
		align-items: center;
		cursor: pointer;
		display: flex;
		line-height: 42px;
		padding: var(--gap) 18px;
		white-space: nowrap;

		&.selected {
			background-color: var(--gray-700);
		}

		&-icon {
			display: block;
			flex-shrink: 0;
			margin-right: 12px;

			svg {
				fill: var(--gray-300);
			}
		}
	}
}
