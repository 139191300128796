@import 'global/variables';
$todo-dropdown-item-color: #e1e3eb;

.dp-dropdown-list {
	position: absolute;
	top: 100%;
	white-space: nowrap;
	z-index: 100;

	&-inner {
		background-color: var(--header-bg-color);
		border-radius: 6px;
		box-shadow: var(--common-box-shadow);
		margin-top: 5px;
		padding: 4px 0 7px;

		a {
			align-items: center;
			color: $todo-dropdown-item-color;
			display: flex;
			font-size: 14px;
			padding: 8px 12px;

			pp-icon {
				fill: $todo-dropdown-item-color;
				margin-right: 8px;
			}

			&:hover {
				background-color: var(--gray-700);
			}
		}
	}
}
