@import 'global/variables';
@import 'global/reset';
@import 'components/index';
@import 'themes/uui-dark-theme';
@import 'themes/uui-light-theme';
@import 'themes/dark-theme';
@import 'themes/light-theme';
@import '@angular/cdk/overlay-prebuilt.css';
@import 'themes/theme';

.modal-overlay-panel {
	z-index: 1030;
}

.selection-overlay-panel {
	z-index: 1010;
}

.lower-overlay-wrapper {
	z-index: 1005 !important;
}

.hint-overlay-panel,
.popover-overlay-panel {
	z-index: 1040;
}

.popover-overlay-panel {
	top: 50px;
}

.cdk-overlay-dark-backdrop {
	background: rgba(0, 0, 0, 75%);
	z-index: 1030;
}

.cdk-overlay-transparent-backdrop {
	background: transparent;
	z-index: 1030;
}

.hidden {
	display: none !important;
}

.invisible {
	visibility: hidden;
}

.off-screen {
	height: 1px;
	left: -1000px;
	position: absolute;
	top: -1000px;
	width: 1px;
}

.centered {
	display: block;
	left: 50%;
	position: absolute;
	top: 50%;
	transform: translateY(-50%) translateX(-50%);
}

@each $extension, $color in (eap, #073F78), (ppt, #f68729), (word, #1856b9), (excel, #21a366), (pdf, #cc4b4c),
	(visio, #2b7cd3), (web, #003399), (msp, #2e7237), (other, #6c6f80), (mail, #e39b0b),
	(confluence, #003399) {

	.color.#{$extension} {
		color: #{$color};
		font-weight: bold;
	}
}

.panel-container {
	background-color: $primary_white;
	border-left: 1px solid $additional_gray_bg_dark;
	color: $additional_black;
	height: 100%;
	overflow: auto;
	padding: 0 20px;
	position: absolute;
	right: 60px;
	width: 260px;
}

// Min supported width 1280px + 300px the width of the panel
// @media (max-width: 1581px) {
// Min window width when 3 tiles displayed 1535px + 300px the width of the panel
@media (max-width: 1835px) {
	.panel-container {
		position: absolute;
		right: 61px;
	}
}

.panel-header {
	@include text($font-sans, $text-size-18x24);

	border-bottom: 1px solid var(--core-surfaces-divider-light);
	line-height: 60px;
	overflow: hidden;
	text-overflow: ellipsis;
}

.warning-sign {
	@include text($font-sans, $text-size-14x18);

	border-bottom: 14px solid $additional_orange;
	border-left: 7px solid transparent;
	border-radius: 3px;
	border-right: 7px solid transparent;
	display: inline-block;
	height: 0;
	line-height: 14px;
	position: relative;
	width: 2px;

	&:after {
		color: $primary_white;
		content: '!';
		left: -1px;
		position: absolute;
	}
}

.underlay {
	background-color: #000;
	height: 100vh;
	left: 0;
	position: fixed;
	text-align: center;
	top: 0;
	width: 100vw;
	z-index: 1000;
}

.new-item-label {
	&:after {
		background: #690;
		border-radius: 100px;
		color: var(--gray-50);
		content: 'New';
		display: inline-block;
		font-family: $font-sans-semibold;
		font-size: 12px;
		line-height: 14px;
		margin-left: 4px;
		padding: 0 5px;
		vertical-align: super;
	}
}
