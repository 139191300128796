@import 'global/variables';

.dp-card {
	background-color: var(--core-surfaces-surface-main);
	border-radius: 6px;
	color: var(--core-text-text-primary);
	margin: 0;
	outline: 1px solid var(--core-surfaces-divider-light);
	outline-offset: -1px;
	padding: 9px $dp-card-padding-right 15px $dp-card-padding-left;

	@media (max-width: $breakpoint-tablet) {
		margin: 0;
	}
}

.dp-card-header {
	align-items: center;
	display: flex;
	justify-content: space-between;
	line-height: 30px;

	@include text($font-sans-semibold, $text-size-18x24);

	&-more-link {
		color: var(--main-active-color);
		font-size: 14px;
		line-height: 24px;

		&:hover {
			color: var(--hover-secondary-color);
		}
	}
}

.dp-card-content {
	margin-top: 12px;
}
